<template>
    <div class="pa-4">
        <v-form @submit.prevent="localOnSubmit" ref="formRef">
            <div class="grid-container">
                <v-text-field dense name="nome" v-model="currentItem.nome" :rules="requiredField" outlined>
                    <template v-slot:label>
                        <span>Equipamento <span style="color: red;">*</span></span>
                    </template>
                </v-text-field>
                <v-autocomplete dense name="tipoEquipamento" :items="tipoEquipamento" item-text="nome" item-value="id" v-model="currentItem.tipo_equipamento" :rules="requiredField" outlined>
                    <template v-slot:label>
                        <span>Tipo de Equipamento <span style="color: red;">*</span></span>
                    </template>
                </v-autocomplete>
                <vuetify-money dense name="custo" label="Custo Estimado" v-model="currentItem.custo" outlined></vuetify-money>
                <vuetify-money dense label="Valor Equipamento Reposição" v-model="currentItem.valor_cobranca" outlined/>
            </div>
            <v-row dense>
                <v-col>
                    <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
                        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
                        <v-btn :color="currentItem.ativo  ? 'green' : ''">Ativo</v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
            <v-row class="justify-end dense">
                <v-col class="text-end">
                    <v-tooltip top :disabled="isFormValid">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-on="on" v-bind="attrs">
                                <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading="isSubmitting" :disabled="!isFormValid || isSubmitting">Salvar</ConfirmButton>
                            </span>
                        </template>
                        <span>Preencha todos os campos obrigatórios (*) para habilitar o botão</span>
                    </v-tooltip>  
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import api from '@/http'
import UtilsFunc from '../../../../service/utilsFunc'
import ConfirmButton from '../../../ConfirmButton.vue'

const { withCRUDUtils } = UtilsFunc

export default {
    name: "NovoEquipamentoForm",
    components: {
        ConfirmButton
    },
    props: {
        onSubmit: Function,
        loading: Boolean,
        currentItem: { type: Object,
            default: () => ({
                ativo: null,
            })
        }
    },
    data: () => withCRUDUtils ({
        tipoEquipamento: [],
        requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório']
    }),
    computed: {
        isFormValid() {
            return !!(this.currentItem.nome &&
                this.currentItem.tipo_equipamento &&
                this.currentItem.custo &&
                this.currentItem.valor_cobranca 
            )
        }
    },
    methods: {
        async localOnSubmit() {
            const validation = this.$refs.formRef.validate()
            if(!validation) {
                this.$toast.error('Por favor, preencha todos os campos obrigatórios!')
                return false
            }
            this.isSubmitting = true
            try {
                this.onSubmit({
                    ...this.currentItem,
                    ativo: Boolean(this.currentItem.ativo)
                }).finally(() => {
                    this.isSubmitting = false
                })
            } catch (error) {
                console.error('Ocorreu um erro')
            }
        },
        async getTipoEquipamento() {
            const LOADING_NAME = 'get:tipoequipamento'
            this.setLoading(LOADING_NAME)
            
            try {
                const { data } = await api.get('equipamentos/tipoequipamentos/')
                this.tipoEquipamento = data
            } catch (error) {
                this.$toast.error('Desculpe, algo deu errado. ', error)
                console.error('Ocorreu um erro: ', error)
            }
        }
    },
    mounted() {
        this.getTipoEquipamento()
    }
}
</script>

<style scoped>

div.grid-container {
display: flex;
flex-wrap: wrap;
gap: 0.5rem;
}

.flex-item-nome {
flex: 1 1 200px; 
min-width: 400px; 
}

</style>
