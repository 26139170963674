<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-data-table :loading="isLoading('get:nomeequipamentos')" @click:row="selectEquipament" :items="filteredEquipamentos" :headers="headers" :single-expand="singleExpand"
        :expanded.sync="expanded" show-expand class="fixed-header-table" fixed-header  height="700px" :items-per-page="-1" >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id }}
        </template>
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nome}}
        </template>
        <template v-slot:[`item.tipo_equipamento`]="{ item }">
          {{ item.tipo_equipamento }}
        </template>
        <template v-slot:[`item.custo`]="{ item }">
          {{ item.custo | formatReal }}
        </template>
        <template v-slot:[`item.qtd`]="{ item }">
          {{ item.qtd }}
        </template>
        <template v-slot:[`item.valor_cobranca`]="{ item }">
          <div v-if="item.valor_cobranca">
            {{ item.valor_cobranca | formatReal }}
          </div>
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
            <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
          </v-chip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="py-4 grey lighten-2 text-start">
            <EquipamentosExpand :item="item" />
          </td>
        </template>

        <template v-slot:top>
          <v-row class="mt-2">
            <!-- Campo de Pesquisa -->
            <v-col cols="5">
              <v-text-field dense outlined v-model="search" label="Pesquisar" placeholder="Pesquise por Nome ou ID"
                class="mx-0" append-icon="mdi-magnify" clearable />
            </v-col>
            <v-col class="text-end">
              <v-badge left bordered overlap :content="activeFiltersCount">
                <v-btn class="mr-2 elevation-0" text @click="showFilters = !showFilters">
                  <v-icon v-if="!showFilters" left>mdi-filter</v-icon>
                  <v-icon v-else left>mdi-filter-off</v-icon>
                  Filtros
                </v-btn>
              </v-badge>
              <v-btn class="mr-4 elevation-0" text @click="collapseAll">
                Recolher Linhas
              </v-btn>
              <v-btn class="mr-4 elevation-0" text @click="atualizar">
                <v-icon left>mdi-reload</v-icon>
                Atualizar
              </v-btn>
              <v-btn color="success" class="elevation-0" @click="dialog.create = true">
                  <v-icon left>mdi-plus</v-icon>
                  Novo
              </v-btn>
            </v-col>
          </v-row>
          <v-expand-transition>
            <v-card outlined v-show="showFilters" dense>
              <h4 class="ml-4 mt-4">Filtrar por</h4>
              <v-row dense class="py-2 px-4">
                <v-col :cols="12" :lg="4">
                  <v-select hide-details :items="statusOptions" label="Status" item-text="label" item-value="value"
                    v-model="filters.status" outlined dense />
                </v-col>
                <v-col :cols="12" :lg="4">
                  <v-select clearable hide-details :items="tipoEquipamentoOptions" label="Tipo Equipamento"
                    item-text="label" item-value="value" v-model="filters.tipoEquipamento" outlined dense />
                </v-col>
                <v-col :cols="12" :lg="4">
                  <v-select clearable hide-details :items="aquisicaoOptions" label="Aquisição" item-text="nome"
                    item-value="id" v-model="filters.aquisicao" outlined dense />
                </v-col>
              </v-row>
              <v-card-actions>
                <v-row dense>
                  <v-col class="text-end">
                    <v-btn color="red" text class="mr-2" elevation="0" @click="clearFilters">
                      Limpar filtros
                    </v-btn>
                    <v-btn color="success" elevation="0" @click="applyFilters">
                      Aplicar filtros
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-expand-transition>
        </template>

        <template v-slot:[`item.edit`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                <v-icon color="green">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Clique para editar</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog.create">
        <v-card>
          <v-card-title class="sticky-title title-border">
            Equipamento
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog.create = false">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <NovoEquipamentoForm :currentItem="newItem" :onSubmit="createEquipament" :loading="isLoading('post:equipamentos')" />
        </v-card>
      </v-dialog>
     <!-- Edição de equipamento  -->
      <FullscreenDialog v-model="dialog.update">
        <v-card>
          <v-card-title class="sticky-title title-border">
            Editar: Id: {{ selectedRow.id }} -> {{ selectedRow.descricao?.nome }} 
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog.update = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-tabs class="mb-4" v-model="currentTab">
            <v-tab>Dados Equipamento</v-tab>
            <v-tab>Esterelizáveis</v-tab>
            <v-tab>Manutenção</v-tab>
            <v-tab>Dados da Locação</v-tab>
            <v-tab>Histórico de Pacientes</v-tab>
          </v-tabs>
          <v-tabs-items v-model="currentTab">
            <v-tab-item>
              <Edit :currentItem="selectedRow" :onSubmit="updateEquipament" :loading="isLoading('put:equipamentos')" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </FullscreenDialog>
    </v-col>
  </v-row>
</template> 

<script>
import api from '../../http';
import UtilsFunc from "@/service/utilsFunc";
import UtilsPDF from "@/service/utilsPDF";
import FullscreenDialog from "../FullscreenDialog.vue"
import EquipamentosExpand from "./componentes/equipamentos/EquipamentosExpand.vue"
import Edit from "./componentes/equipamentos/Edit.vue"
import NovoEquipamentoForm from './componentes/equipamentos/NovoEquipamentoForm.vue';
// import { mapMutations } from 'vuex';

const { withCRUDUtils } = UtilsFunc;

export default {
  name: 'Equipamentos',
  components: {
    EquipamentosExpand,
    FullscreenDialog,
    NovoEquipamentoForm,
    Edit,
  },
  data: () => withCRUDUtils({
    equipamentos: [],
    expanded: [],
    loading: false,
    search: '',
    singleExpand: false,
    showFilters: false,
    menu: false,
    filters: {
      status: null,
      tipoEquipamento: null,
      aquisicao: null,
      classificacao: null,
    },
    dialog: {
      create: false,
      update: false,
    },
    newItem: {
      ativo: null,
    },
    selectedRow: {},
    currentTab: null,
    headers: [
      { text: "Editar", value: "edit", width: 100 },
      { text: "Código", align: "center", sortable: true, value: "id" },
      { text: "Descrição do Equipamento", align: "start", sortable: true, value: "nome" },
      { text: "Tipo", align: "center", sortable: true, value: "tipo_equipamento.nome" },
      { text: "Custo estimado", align: "center", sortable: true, value: "custo" },
      { text: "Quantidade", align: "start", sortable: true, value: "qtd" },
      { text: "Valor do Equipamento para Reposição", align: "center", sortable: true, value: "valor_cobranca" },
      { text: "Status", align: "start", sortable: true, value: "ativo" },
    ],
    statusOptions: [
      {label: "Ativo", value: 'True'},
      {label: "Inativo", value: 'False'},
    ],
    tipoEquipamentoOptions: [
      {label: "Medição", value: "Medição"},
      {label: "Apoio Respiratório", value: "Apoio Respiratório"},
      {label: "Gás Medicinal", value: "Gás Medicinal"},
      {label: "Mobiliário", value: "Mobiliário"},
    ],
    aquisicaoOptions: [],
  }),
  computed: {
    activeFiltersCount() {
      return Object.values(this.filters).filter(Boolean).length;
    },
    filteredEquipamentos() {
      if (!this.search) {
        return this.equipamentos;
      }

      const searchTerm = this.search.toLowerCase();

      return this.equipamentos.filter(equipamento => {
        return Object.keys(equipamento).some(key => {
          const value = equipamento[key];
          if (typeof value === 'string') {
            return value.toLowerCase().includes(searchTerm);
          } else if (typeof value === 'object' && value !== null) {
            return Object.keys(value).some(subKey => {
              const subValue = value[subKey];
              return typeof subValue === 'string' && subValue.toLowerCase().includes(searchTerm);
            });
          }
          return false;
        });
      });
    },
  },
  methods: {
    // ...mapMutations(['setSelectedEquipament']),
    // selectEquipament(equipament) {
    //   this.setSelectedEquipament(equipament)
    //   console.log('Equipamento selecionado: ', equipament)
    // },
    formatDate(date) {
      return UtilsPDF.formatDate(date);
    },
    async applyFilters() {
      this.search = ''
      await this.getEquipamentos();
      if(this.filters.status !== null) {
        const ativo = this.filters.status === 'True' ? true : false
        this.equipamentos = this.equipamentos.filter(equipamento => equipamento.ativo === ativo)
      }

      if(this.filters.tipoEquipamento !== null) {
        this.equipamentos = this.equipamentos.filter(equipamento => equipamento.tipoEquipamento.nome === this.filters.tipoEquipamento)
        return this.equipamentos
      }
    },
    clearFilters() {
      this.activeFilters = [];
      this.search = '';
      this.filters.status = null
      this.filters.tipoEquipamento = null;
      this.filters.aquisicao = null;
      this.filters.classificacao = null;
      this.getEquipamentos();
    },
    async getEquipamentos() {
      const LOADING_NAME = "get:nomeequipamentos";
      this.setLoading(LOADING_NAME);
      this.loading = true;
      try {
        const response = await api.get('equipamentos/nomeequipamentos/');
        this.equipamentos = response.data;
        console.log('Equipamentos -->', this.equipamentos)
      } catch (error) {
        console.error(error);
      } finally {
        this.setLoading(LOADING_NAME, true);
        this.loading = false;
      }
    },
    async createEquipament() {
      const LOADING_NAME = "post:equipamentos";
      this.setLoading(LOADING_NAME);
      try {
        const fields = {
          ...this.newItem
        }

        console.log("Dados do equipamento", fields)

        await api.post("equipamentos/nomeequipamentos/", fields);
        await this.getEquipamentos();
        this.newItem = {
          ativo: true
        }
        this.dialog.create = false;
        this.$toast.success('Equipamento Criado com Sucesso!')
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async updateEquipament(fields) {
      const LOADING_NAME = "put:equipamentos";
      this.setLoading(LOADING_NAME);
      try {
        await api.put(`equipamentos/equipamento/${fields.id}/`, fields);
        await this.getEquipamentos();
        this.dialog.update = false;
        this.$toast.success('Equipamento Atualizado com Sucesso!')
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    collapseAll() {
      this.expanded = [];
    },
    async atualizar() {
      this.collapseAll();
      this.clearFilters();
      this.search = '';
      this.getEquipamentos();
    },
  },
  mounted() {
    this.getEquipamentos();
  }
}
</script>

<style scoped>
.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Centraliza os elementos horizontalmente */
  flex-wrap: nowrap;
  /* Impede que os elementos sejam quebrados em linhas diferentes */
}

.table-container {
  max-height: 500px; /* Define a altura máxima para o contêiner da tabela */
  overflow-y: auto;  /* Permite a rolagem vertical */
}

.fixed-header-table thead th {
  background-color: white; /* Cor de fundo do cabeçalho para que ele permaneça visível */
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
